import getOptimizelyUserId from 'utils/optimizely/getOptimizelyUserId';

const locale = process.env.GATSBY_COUNTRY_CODE;

export const types = {
  book: 'BOOK',
  reschedule: 'RESCH',
};

/**
 * Creating gtm evetn for survey.
 * @param {String} eventAction
 * @returns {Object}
 */
const createGTMSurveyEvent = eventAction => ({
  event: 'gtmEvent',
  eventCategory: 'Survey',
  eventAction,
});

/**
 * Create event body for GTM event.
 * @param {String} eventAction
 * @returns {Object}
 */
const createGTMBookingEventBody = eventAction => ({
  event: 'gtmEvent',
  eventCategory: 'Booking funnel',
  isEventNonInteraction: 'False',
  eventAction,
});

export const getType = type => {
  const availableTypes = Object.keys(types);

  if (!availableTypes.includes(type)) return types.book;

  return types[type];
};

function pushToDataLayer(gtmEvent) {
  if (!window || !window.dataLayer) return;

  window.dataLayer.push(gtmEvent);
}

function pushToOptimizely(optimizely, eventName = '', eventBody = {}) {
  const userId = getOptimizelyUserId();
  optimizely.track(eventName, userId, eventBody);
}

export function getPtsrcParam() {
  if (!window) return;
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.toString();
}

export function pushGTMBookingCancelledEvent({ location, service, cancelReason }) {
  const gtmEvent = {
    event: 'Appointment Cancelled',
    attributes: {
      ptid: 'contentful',
      location,
      service,
      cancelReason,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMClosingPopupComponent({ title, lang }) {
  const gtmEvent = {
    event: 'userClosedComponentPopup',
    attributes: {
      ptid: 'contentful',
      title,
      lang,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMUserLocationPrompted() {
  const gtmEvent = {
    event: 'userLocationAskedViaPopup',
    attributes: {
      locale,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMUserLocationGranted() {
  const gtmEvent = {
    event: 'userLocationGranted',
    attributes: {
      locale,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMUserLocationDeclined() {
  const gtmEvent = {
    event: 'userLocationDeclined',
    attributes: {
      locale,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMChangingLocationDropdown({ location, code }) {
  const gtmEvent = {
    event: 'toggleLocationDropdown',
    attributes: {
      location,
      code,
      locale,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMAppointmentTimeSelectedEvent(
  optimizely,
  { location, hourOfDay, selectedDate, currentDate, daysBetween, type = types.book, service, funnelType },
) {
  pushToOptimizely(optimizely, 'AppointmentTimeSelectedEvent');

  const gtmEvent = {
    event: 'Appointment Time selected',
    attributes: {
      ptid: 'contentful',
      type,
      service,
      ptsrc: getPtsrcParam(),
      location,
      hourOfDay,
      selectedDate,
      currentDate,
      daysBetween,
      funnelType,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMBookingFormDisplayedEvent(
  optimizely,
  {
    location,
    hourOfDay,
    selectedDate,
    currentDate,
    daysBetween,
    coronaShuttleAvailable = false,
    type = types.book,
    service,
    funnelType,
  },
) {
  pushToOptimizely(optimizely, 'BookingFormDisplayedEvent');

  const gtmEvent = {
    event: 'Booking Form Displayed',
    attributes: {
      ptid: 'contentful',
      type,
      service,
      ptsrc: getPtsrcParam(),
      location,
      hourOfDay,
      selectedDate,
      currentDate,
      daysBetween,
      coronaShuttleAvailable,
      funnelType,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMBookingLeadCreated(optimizely, { ...eventAttributes }) {
  pushToOptimizely(optimizely, 'BookingLeadCreatedEvent');

  const gtmEvent = {
    event: 'Lead Created',
    attributes: {
      ptid: 'contentful',
      ...eventAttributes,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMAppointmentBookedEvent(
  optimizely,
  {
    location,
    hourOfDay,
    selectedDate,
    currentDate,
    service,
    daysBetween,
    appointmentId,
    coronaShuttleAvailable,
    coronaShuttleRequested,
    pickupRequested,
    returnTripRequested,
    personalDetails,
    type = types.book,
    customer,
    funnelType,
  },
) {
  pushToOptimizely(optimizely, 'AppointmentBookedEvent');

  const gtmEvent = {
    event: 'Appointment Booked',
    attributes: {
      ptid: 'contentful',
      email: personalDetails.email,
      type,
      service,
      ptsrc: getPtsrcParam(),
      location,
      hourOfDay,
      selectedDate,
      currentDate,
      daysBetween,
      appointmentId,
      coronaShuttleAvailable,
      coronaShuttleRequested,
      pickupRequested,
      returnTripRequested,
      customer,
      funnelType,
    },
  };
  pushToDataLayer(gtmEvent);
}

export function pushGTMCalendarDisplayedEvent(
  optimizely,
  {
    location,
    coronaShuttleAvailable = false,
    type = types.book,
    slots = {},
    totalAmountOfSlots,
    days,
    extraDays,
    newSlots,
    service,
    funnelType,
  },
) {
  pushToOptimizely(optimizely, 'CalendarDisplayedEvent', { totalAmount: totalAmountOfSlots });

  const gtmEvent = {
    event: 'Calendar Displayed',
    attributes: {
      ptid: 'contentful',
      type,
      service,
      funnelType,
      location,
      coronaShuttleAvailable,
      slots,
      totalAmount: totalAmountOfSlots,
      days,
      extraDays,
      ...newSlots,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMAppointmentDetailsDisplayedEvent(
  optimizely,
  { location, service, type = types.book, funnelType },
) {
  pushToOptimizely(optimizely, 'AppointmentDetailsDisplayedEvent');

  const gtmEvent = {
    event: 'Appointment Details Displayed',
    attributes: {
      ptid: 'contentful',
      type,
      service,
      location,
      funnelType,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMUpdateBookingDisplayedEvent(optimizely, { location, service, funnelType }) {
  pushToOptimizely(optimizely, 'UpdateBookingDisplayedEvent');

  const gtmEvent = {
    event: 'Update Booking Displayed',
    attributes: {
      ptid: 'contentful',
      type: types.reschedule,
      service,
      location,
      funnelType,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMLocationsDisplayedEvent(optimizely, { service, type = types.book, funnelType = 'normal' }) {
  pushToOptimizely(optimizely, 'LocationsDisplayedEvent');

  const gtmEvent = {
    event: 'Locations Displayed',
    attributes: {
      ptid: 'contentful',
      type,
      service,
      funnelType,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMPageView(optimizely, eventProperties) {
  const countryDomain = process.env.GATSBY_COUNTRY_CODE.slice(-2).toUpperCase();

  pushToOptimizely(optimizely, 'GTMPageView');

  const gtmEvent = {
    ...eventProperties,
    countryDomain,
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMErrorEvent(optimizely, { href }) {
  pushToOptimizely(optimizely, 'ErrorEvent');

  const gtmEvent = {
    event: 'Error',
    errorCode: 404,
    pageUrl: href,
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMQuestionnaireStartedEvent(optimizely) {
  const eventName = 'CV-0-Questionnaire_started';

  pushToOptimizely(optimizely, eventName);

  const gtmEvent = { event: eventName };

  pushToDataLayer(gtmEvent);
}

export function pushGTMQuestionnaireFinishedEvent(optimizely) {
  const eventName = 'CV-0-Questionnaire_finished';

  pushToOptimizely(optimizely, eventName);

  const gtmEvent = { event: eventName };

  pushToDataLayer(gtmEvent);
}

export function pushGTMInteractionOnMapEvent(optimizely, action) {
  const hasEvent = optimizely.initialConfig.datafile.events.some(
    optimizelyEvent => optimizelyEvent.key === 'InteractionOnMap',
  );

  if (!hasEvent) return;

  pushToOptimizely(optimizely, 'InteractionOnMap');

  const gtmEvent = {
    event: 'Interaction On Map',
    attributes: {
      action,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMBookingFormSubmitted(optimizely, gtmEvent) {
  if (optimizely) pushToOptimizely(optimizely, 'BookingFormSubmitted');
  pushToDataLayer(gtmEvent);
}

export function pushGTMBookedOutLocationEvent(optimizely, { location }) {
  pushToOptimizely(optimizely, 'BookedOutLocation');

  const gtmEvent = {
    ...createGTMBookingEventBody('booked out location'),
    attributes: {
      location,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGMTWaitlistOpenedEvent(optimizely) {
  pushToOptimizely(optimizely, 'WaitlistOpened');

  const gtmEvent = createGTMBookingEventBody('waitlist opened');

  pushToDataLayer(gtmEvent);
}

export function pushGMTWaitlistShownEvent() {
  const gtmEvent = createGTMBookingEventBody('waitlist shown');

  pushToDataLayer(gtmEvent);
}

export function pushGTMWaitlistCompletedEvent(optimizely, location) {
  pushToOptimizely(optimizely, 'WaitlistCompleted');

  const gtmEvent = {
    ...createGTMBookingEventBody('waitlist completed'),
    attributes: {
      location,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMWaitlistSuccessEvent(location) {
  const gtmEvent = {
    ...createGTMBookingEventBody('waitlist success'),
    attributes: {
      location,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMWaitlistFailureEvent(location) {
  const gtmEvent = {
    ...createGTMBookingEventBody('waitlist failure'),
    attributes: {
      location,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMSurveyStartedEvent() {
  const gtmEvent = createGTMSurveyEvent('SurveyStarted');

  pushToDataLayer(gtmEvent);
}

export function pushGTMSurveyDisqualifiedEvent() {
  const gtmEvent = createGTMSurveyEvent('SurveyDisqualified');
  gtmEvent.eventNonInteraction = 'False';

  pushToDataLayer(gtmEvent);
}

export function pushGTMSurveyCompletedEvent() {
  const gtmEvent = createGTMSurveyEvent('SurveyCompleted');
  gtmEvent.eventNonInteraction = 'False';

  pushToDataLayer(gtmEvent);
}

export function pushGTMChooseLocationEvent(location, funnelType = 'normal') {
  const gtmEvent = {
    event: 'gtmEvent',
    eventCategory: 'Booking funnel',
    eventAction: 'location submitted',
    eventNonInteraction: 'False',
    attributes: {
      location,
      funnelType,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMLeadCreatedEvent(optimizely, funnelType = 'normal') {
  const eventName = 'CV-3-lead-created';

  pushToOptimizely(optimizely, eventName);

  const gtmEvent = { event: eventName, funnelType };

  pushToDataLayer(gtmEvent);
}

export function pushGTMEvent({ eventAction, eventCategory, optimizely }) {
  if (!eventAction || !eventCategory) return;

  if (optimizely) pushToOptimizely(optimizely, eventAction);

  const gtmEvent = {
    event: 'gtmEvent',
    eventCategory,
    eventAction,
    eventNonInteraction: 'False',
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMCESRating({ score, appointmentId }) {
  const gtmEvent = {
    event: 'CESratingSubmitted',
    attributes: {
      score,
      appointmentId,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMDayFilterAdded(date) {
  const gtmEvent = {
    event: 'Day Filter Added',
    attributes: {
      dateApiFormat: date.apiFormat,
      dateRenderedFormat: date.formatted,
    },
  };

  pushToDataLayer(gtmEvent);
}

export function pushGTMDayFilterRemoved(date) {
  const gtmEvent = {
    event: 'Day Filter Removed',
    attributes: {
      dateApiFormat: date.apiFormat,
      dateRenderedFormat: date.formatted,
    },
  };

  pushToDataLayer(gtmEvent);
}
